/* eslint-disable @typescript-eslint/no-unused-vars */
import 'components/organisms/Table/_table.scss'
import { isEmpty, noop } from 'lodash'
import camelCase from 'lodash/camelCase'
import map from 'lodash/map'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

interface TableConfig {
  loading?: boolean
  curTab?: string
  onSort?: () => void
  sortMethod?: string
  setOnMembers?: any
  tableWidth?: any
  onCreate?: () => void
  viewModal?: boolean
  config?: {
    headers?: { textColor?: string; bgColor?: string; textSize?: string; textCase?: string; textWeight?: string }
    dataList?: {
      textSize?: string
      link?: boolean
      items?: { [key: string]: any }[]
      withImage?: React.ReactNode
      onItemClick?: (...args: any[]) => void
      textColor?: string
      dropdown?: (item?: any) => void | React.ReactNode
      bgColor?: string
      pattern?: string
      selectedItem?: any
      patternConfig?: { firstColor?: string; secondColor?: string }
    }
  }
  headers: string[]
  dataList: { [key: string]: any }[]
}

const SubGoalTable = (props: TableConfig) => {
  const { dataList, headers, config, loading, curTab, tableWidth, onCreate, viewModal } = props

  const loadingList = [
    { id: '1', fullName: '', title: '', permissions: '' },
    { id: '2', fullName: '', title: '', permissions: '' },
  ]
  const {
    textColor: hTextColor,
    bgColor: hBgColor,
    textSize: hTextSize,
    textCase: hTextCase,
    textWeight: hTextWeight,
  } = config?.headers || {} // h = header
  const {
    textColor: bTextColor,
    textSize: bTextSize,
    pattern,
    patternConfig,
    dropdown,
    onItemClick = noop,
    selectedItem,
    withImage,
    link = false,
  } = config?.dataList || {} // b = body

  const [selectedRow, setSelectedRow] = useState<number | null>(null)

  // Column widths
  const colWidth = {
    width: Math.round(window.innerWidth * 0.08),
    minWidth: Math.round(window.innerWidth * 0.08),
    maxWidth: Math.round(window.innerWidth * 0.08),
  }

  const titleWidth = {
    width: Math.round(window.innerWidth * 0.45),
    minWidth: Math.round(window.innerWidth * 0.35),
    maxWidth: Math.round(window.innerWidth * 0.5),
  }

  const getValue = (loading: boolean, header: string, idx: number, value: any) => {
    if (loading) {
      return <div className="h-5 bg-gray-200 animate-pulse rounded"></div>
    } else if (header === 'dropdown') {
      return dropdown(config?.dataList?.items[idx])
    } else {
      return !value?.type ? moment(value).format('MM/DD/YYYY') : value || '--'
    }
  }
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      let newSelectedRow = selectedRow // Tracks the current index
      const rowCount = dataList.length

      if (event.key === 'ArrowDown') {
        // Move to next row
        if (newSelectedRow === null) {
          newSelectedRow = 0 // Select the first row if none is selected
        } else if (newSelectedRow < rowCount - 1) {
          newSelectedRow += 1 // Move to the next row
        }
      }

      if (event.key === 'ArrowUp') {
        // Move to previous row
        if (newSelectedRow > 0) {
          newSelectedRow -= 1 // Move to the previous row
        }
      }
      if (event.key === 'Enter' && newSelectedRow !== null) {
        const selectedItem = config.dataList.items?.[newSelectedRow]
        onItemClick(selectedItem) // Directly invoke the action
      }

      // Update selected row and scroll it into view
      if (newSelectedRow !== selectedRow) {
        setSelectedRow(newSelectedRow) // Update the state

        const rowElement = document.getElementById(`row-${newSelectedRow}`)
        if (rowElement) {
          rowElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedRow, dataList, onItemClick])

  const handleRowClick = (idx: number, item: any) => {
    // Update selected row on click (via mouse)
    setSelectedRow(idx)
    onItemClick(item)
  }

  return (
    <div className="flex flex-col">
      <div className="py-2 align-middle inline-block min-w-full">
        <div className={`sm:rounded-lg`}>
          <table
            className={`${!isEmpty(tableWidth) ? tableWidth : 'min-w-full'} divide-y-0 divide-gray-700 font-roboto`}
          >
            <thead className={`${hBgColor} border-b`}>
              <tr className="m-0">
                {map(headers, (header, idx) => (
                  <th
                    key={header}
                    scope="col"
                    style={header === 'Title' ? titleWidth : colWidth}
                    className={`${curTab === 'cascading' && header === 'Attachment' ? 'hidden' : 'w-full'} ${
                      hTextColor || 'text-gray-500'
                    } px-2 py-4 ${header === 'dropdown' ? 'text-right pr-4' : header === 'Title' ? 'text-left' : ''} ${
                      hTextSize || 'text-base'
                    } ${hTextCase || 'uppercase'} ${hTextWeight || 'font-bold'} tracking-wider`}
                  >
                    {header === 'dropdown' ? '' : header}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className="text-center">
              {map(loading ? loadingList : dataList, (item, idx) =>
                loading ? (
                  <tr
                    role="button"
                    onClick={() => {
                      const item = config?.dataList?.items[idx]
                      onItemClick(item)
                    }}
                    className={` ${
                      bTextColor
                        ? bTextColor
                        : pattern === 'striped'
                        ? idx % 2 === 0
                          ? `${patternConfig?.firstColor}`
                          : `${patternConfig?.secondColor}`
                        : 'bg-transparent hover:bg-gray-100 cursor-pointer  border-b border-gray-200 '
                    }`}
                    key={idx}
                  >
                    {map(headers, (header, _idx) => {
                      return (
                        <td
                          key={item.id + '-' + header}
                          style={header === 'Title' ? titleWidth : colWidth}
                          className={`${bTextColor || 'text-gray-500'}  px-4 py-4  ${
                            header === 'dropdown' ? 'text-right pr-4' : 'text-left'
                          } whitespace-nowrap text-base`}
                        >
                          <div className="h-4 bg-gray-200 animate-pulse duration-700 rounded"></div>
                        </td>
                      )
                    })}
                  </tr>
                ) : (
                  <tr
                    role="button"
                    onClick={() => {
                      const item = config?.dataList?.items[idx]
                      onItemClick(item)
                    }}
                    className={` ${
                      bTextColor
                        ? bTextColor
                        : pattern === 'striped'
                        ? idx % 2 === 0
                          ? `${patternConfig?.firstColor}`
                          : `${patternConfig?.secondColor}`
                        : ` ${
                            selectedRow === idx ? 'bg-blue-500 text-white' : 'bg-transparent hover:bg-gray-100'
                          } font-roboto cursor-pointer  border-b border-gray-200 `
                    }`}
                    key={idx}
                    id={`row-${idx}`} // Set unique ID for each row
                  >
                    {map(headers, (header, _idx) => {
                      const value = item[camelCase(header.toLowerCase())]
                      return (
                        <td
                          key={item.id + '-' + header}
                          style={header === 'Title' ? titleWidth : colWidth}
                          className={`${bTextSize || 'text-base'} ${
                            bTextColor || 'text-black'
                          }  px-2 py-2 whitespace-nowrap`}
                        >
                          {getValue(loading, header, idx, value)}
                        </td>
                      )
                    })}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default SubGoalTable
