/* eslint-disable @typescript-eslint/no-unused-vars */
import { BsCheck2Square } from 'react-icons/bs'
import _, { capitalize } from 'lodash'
import { ReactComponent as OffTrackIcon } from 'assets/icons/GoalExplore/GoalStatus/offTrack.svg'
import { ReactComponent as ProcessingIcon } from 'assets/icons/GoalExplore/GoalStatus/processing.svg'
import { ReactComponent as OnTrackIcon } from 'assets/icons/GoalExplore/GoalStatus/onTrack.svg'
import Label from 'components/atoms/Labels/Label'
import { BiMinus } from 'react-icons/bi'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import { AiOutlineCheck, AiOutlineMinus } from 'react-icons/ai'
import { get, isEmpty, map } from 'lodash'
import Avatar from 'components/atoms/Avatar'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useCompanyTools } from 'hooks/useCompanyTools'
import { useDispatch, useSelector } from 'react-redux'
import { useMeetings } from 'components/templates/Meeting/ContextApi/MeetingContext'
import { useOneOnOneTools } from 'hooks/useOneOnOneTools'
import { setSuccessMessage } from 'state/redux/actions/settings.actions'
import { useCreateOneOnOne } from 'components/templates/Meeting/ContextApi/CreateOneOnOneContext'
import { INITIAL_DATE_RANGE } from 'pages/Goal/Explore'
import { truncate as lTruncate } from 'lodash'

const getLocalCompany = () => {
  let company = localStorage.getItem('company')
  if (company) {
    return JSON.parse(localStorage.getItem('company'))
  } else {
    return {}
  }
}

export const ExploreHooks = () => {
  const navigate = useNavigate()
  const user: any = useSelector((state) => get(state, 'user.user'))
  const [isFetching, setIsFetching] = useState(false)
  const [rooms, setRooms]: any = useState()
  const { getCompanyUserInfo } = useCompanyTools()
  const [companyUserInfo, setCompanyUserInfo]: any = useState(getLocalCompany)
  const [selectedCompany, setSelectedCompany]: any = useState()
  const { companyId }: any = useParams()
  const [company, setCompany]: any = useState()
  const { fetchMeetings, fetchMeetingContext, refetchData } = useMeetings()
  const { openModal } = useCreateOneOnOne()
  const [ownerEmp, setOwnerEmp]: any = useState()
  const [disableSidebar, setDisableSidebar] = useState(false)

  const fetchCompanyUserInfo = async () => {
    localStorage.getItem('company') === '{}' && setIsFetching(true)
    try {
      const data = await getCompanyUserInfo(company.employeeId)
      setCompanyUserInfo(data)
      setIsFetching(false)
    } catch (e) {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    localStorage.setItem('company', JSON.stringify(companyUserInfo))
  }, [companyUserInfo])

  const getCompanyName = () => {
    user?.employee?.forEach((company) => {
      if (company.companyId === parseInt(companyId)) {
        setCompany(company)
      }
    })
  }

  useEffect(() => {
    getCompanyName()
  }, [company, getCompanyName])

  useEffect(() => {
    fetchCompanyUserInfo()
  }, [company])

  useEffect(() => {
    const dReportList = _.cloneDeep(companyUserInfo?.team)
    const oneOnOneList = _.cloneDeep(fetchMeetings?.oneOnOne)
    if (!isEmpty(dReportList) && !isEmpty(oneOnOneList)) {
      const updatedList = dReportList.map((item) => {
        const oneOnOne = oneOnOneList.filter((one) => one?.employments?.id !== item?.employeeId)
        return oneOnOne
      })[0]

      setRooms(updatedList)
    } else {
      setRooms(fetchMeetings?.oneOnOne)
    }
  }, [fetchMeetings, companyUserInfo])

  const selectedClass = 'bg-white shadow'

  const getActiveClass = (list, id) => {
    let tempObj = list.find((item) => item.id === id)
    return tempObj?.isActive ? selectedClass : ''
  }

  const dispatch: any = useDispatch()
  const { hideOldOneOnOne } = useOneOnOneTools()

  const handleHideOneOnOne = (user) => {
    const meetingRoomId = user.oneOnOneId
    const onSuccess = () => {
      refetchData()
      dispatch(setSuccessMessage('One on One has been hidden successfully'))
    }
    hideOldOneOnOne(meetingRoomId, companyId, onSuccess)
  }

  const truncate = (str) => {
    const maxLength = 12
    if (str?.length > maxLength) {
      return str?.slice(0, maxLength) + '...'
    } else {
      return str
    }
  }

  const sidebarContent = ({
    selected,
    setSelected,
    selectedCompany,
    ownersItems,
    typeItems,
    ownerNameList,
    onCheckboxChange,
    typeDepartmentList,
    typeTeamList,
    onChipClose,
    setHeaderLabel,
    showMoreOne,
    setShowMoreOne,
    typeCompany,
    setTypeCompany,
    setStartDate,
    setDueDate,
    setFilter,
    setOwnerNameList,
    setTypeTeams,
    setTypeTeam,
    setTypeDepartments,
    setTypeDepartment,
    setTypeIndividual,
    setTypeAny,
    numberOfEmployees,
    setSearchInput,
  }: any) => (
    <div className="font-roboto">
      <div className="pl-8 mb-5">
        <h4 className={'capitalize text-gray-600 font-bold text-sm flex items-center gap-2 cursor-pointer'}>
          <Tooltip position={'bottom'} text={selectedCompany?.companyName}>
            {truncate(selectedCompany?.companyName)} Goals
          </Tooltip>
        </h4>
      </div>

      <div
        className={`flex items-center px-8 pt-2 pb-2 mb-2 justify-start ${getActiveClass(
          ownerNameList,
          selectedCompany?.employeeId
        )} ${disableSidebar ? 'cursor-not-allowed pointer-events-none text-gray-500' : 'cursor-pointer'}`}
        onClick={() => {
          navigate(`/goals/explore/${companyId}`)
          onChipClose({ title: 'all' })
          setFilter({
            id: 'a',
            name: 'Any',
          })
          setSearchInput('')
          setTypeAny(true)
          setStartDate(INITIAL_DATE_RANGE.startDate)
          setDueDate(INITIAL_DATE_RANGE.dueDate)
          ownerNameList.forEach((item) => (item.isActive = false))
          let tempObj = ownerNameList.find((item) => item.id === selectedCompany?.employeeId)
          setOwnerEmp(tempObj)
          tempObj = {
            ...tempObj,
            isActive: true,
          }
          setHeaderLabel(tempObj)
          onCheckboxChange(tempObj, ownersItems[0], true)
        }}
      >
        <Avatar small name={user?.fullName} image={user?.profileImageThumbnailUrl} />
        <h3 className="font-semibold text-sm pl-3">My Goals</h3>
      </div>

      {companyUserInfo?.team && companyUserInfo?.team[0] && (
        <>
          <div className="px-4 mt-5 ">
            <hr className="border-themeGray3" />
          </div>
          <div
            className={`flex items-center px-8 py-2  justify-start cursor-pointer ${
              selected === 'directReport' && selectedClass
            }`}
            onClick={() => {
              navigate(`/goals/explore/${companyId}`)
              setSelected('directReport')
            }}
          >
            <h3 className="font-semibold text-sm">Direct Report Goals</h3>
          </div>
        </>
      )}

      {isFetching ? (
        <div className="space-y-4 pt-4 px-5">
          <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
          <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
          <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
          <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
        </div>
      ) : (
        <>
          {map(
            companyUserInfo?.team?.filter((item) => item?.status === 'Active'),
            (people, idx) => (
              <div
                className={`flex items-center px-8 py-2 justify-start ${getActiveClass(
                  ownerNameList,
                  people.employeeId
                )} ${disableSidebar ? 'cursor-not-allowed pointer-events-none text-gray-500' : 'cursor-pointer'}`}
                onClick={() => {
                  navigate(`/goals/explore/${companyId}`)
                  onChipClose({ title: 'all' })
                  setFilter({
                    id: 'a',
                    name: 'Any',
                  })
                  setSearchInput('')
                  setTypeAny(true)
                  setStartDate(INITIAL_DATE_RANGE.startDate)
                  setDueDate(INITIAL_DATE_RANGE.dueDate)
                  ownerNameList.forEach((item) => (item.isActive = false))
                  let tempObj = ownerNameList.find((item) => item.id === people?.employeeId)
                  setOwnerEmp(tempObj)
                  tempObj = {
                    ...tempObj,
                    isActive: true,
                  }
                  setHeaderLabel(tempObj)
                  onCheckboxChange(tempObj, ownersItems[0], true)
                }}
                key={idx}
              >
                <Avatar small name={people?.fullName} image={people?.profileImageThumbnailUrl} />
                <h3 className="font-semibold text-sm ml-3 capitalize">{people?.fullName}</h3>
              </div>
            )
          )}

          <div className="px-4 mt-5 ">
            <hr className="border-themeGray3" />
          </div>

          <div className={`flex items-center gap-6 px-8 py-2 justify-between`}>
            <h3 className="font-semibold text-sm">Skip Level Goals</h3>
          </div>

          <div className="my-2">
            {map(
              rooms?.filter((item) => item?.employments?.status === 'Active'),
              (o, idx) => (
                <>
                  {idx < '5' && (
                    <div
                      style={{ paddingLeft: '2rem', paddingRight: '1.4rem' }}
                      className={`flex items-center py-2 justify-between cursor-pointer 
                    ${getActiveClass(ownerNameList, o?.employments?.id)} ${
                        disableSidebar ? 'cursor-not-allowed pointer-events-none text-gray-500' : 'cursor-pointer'
                      }
                `}
                      key={idx}
                    >
                      <div
                        style={{ width: '140px' }}
                        onClick={() => {
                          navigate(`/goals/explore/${companyId}`)
                          onChipClose({ title: 'all' })
                          setFilter({
                            id: 'a',
                            name: 'Any',
                          })
                          setSearchInput('')
                          setTypeAny(true)
                          setStartDate(INITIAL_DATE_RANGE.startDate)
                          setDueDate(INITIAL_DATE_RANGE.dueDate)
                          ownerNameList.forEach((item) => (item.isActive = false))
                          let tempObj = ownerNameList.find((item) => item.id === o?.employments?.id)
                          setOwnerEmp(tempObj)
                          tempObj = {
                            ...tempObj,
                            isActive: true,
                          }
                          setHeaderLabel(tempObj)
                          onCheckboxChange(tempObj, ownersItems[0], true)
                        }}
                        className="flex items-center truncate"
                      >
                        <Avatar
                          small
                          name={o?.employments?.fullName}
                          image={o?.employments?.profileImageThumbnailUrl}
                        />
                        <h3 className="font-semibold text-sm ml-3 capitalize">{truncate(o?.employments?.fullName)}</h3>
                      </div>
                      <div>
                        <h3
                          onClick={() => handleHideOneOnOne(o)}
                          className="font-semibold text-sm hover:text-blue-500 cursor-pointer"
                        >
                          - 1:1
                        </h3>
                      </div>
                    </div>
                  )}
                </>
              )
            )}
            {showMoreOne &&
              map(rooms, (o, idx) => (
                <>
                  {idx > '4' && (
                    <div
                      style={{ paddingLeft: '2rem', paddingRight: '1.4rem' }}
                      className={`flex items-center px-8 py-2 justify-between cursor-pointer 
                      ${getActiveClass(ownerNameList, o?.employments?.id)} ${
                        disableSidebar ? 'cursor-not-allowed pointer-events-none text-gray-500' : 'cursor-pointer'
                      }
                `}
                      key={idx}
                    >
                      <div
                        style={{ width: '130px' }}
                        onClick={() => {
                          navigate(`/goals/explore/${companyId}`)
                          onChipClose({ title: 'all' })
                          setFilter({
                            id: 'a',
                            name: 'Any',
                          })
                          setSearchInput('')
                          setTypeAny(true)
                          setStartDate(INITIAL_DATE_RANGE.startDate)
                          setDueDate(INITIAL_DATE_RANGE.dueDate)
                          ownerNameList.forEach((item) => (item.isActive = false))
                          let tempObj = ownerNameList.find((item) => item.id === o?.employments?.id)
                          setOwnerEmp(tempObj)
                          tempObj = {
                            ...tempObj,
                            isActive: true,
                          }
                          setHeaderLabel(tempObj)
                          onCheckboxChange(tempObj, ownersItems[0], true)
                        }}
                        className="flex items-center truncate"
                      >
                        <Avatar
                          small
                          name={o?.employments?.fullName}
                          image={o?.employments?.profileImageThumbnailUrl}
                        />
                        <h3 className="font-semibold text-sm ml-3 capitalize">{truncate(o?.employments?.fullName)}</h3>
                      </div>
                      <div>
                        <h3
                          onClick={() => handleHideOneOnOne(o)}
                          className="font-semibold text-sm hover:text-blue-500 cursor-pointer"
                        >
                          - 1:1
                        </h3>
                      </div>
                    </div>
                  )}
                </>
              ))}
            {rooms && rooms?.length > 4 && (
              <p
                className="text-sm text-blue-500 cursor-pointer mr-5 text-right"
                onClick={() => {
                  setShowMoreOne(!showMoreOne)
                }}
              >
                {showMoreOne ? 'Show less' : 'Show more'}
              </p>
            )}
          </div>
          {!rooms?.[0] && (
            <div className="my-2 font-semibold px-8">
              <h3 className="text-sm pl-3 border-l-2 border-pink-400">No Relationships</h3>
            </div>
          )}

          <div className={`flex items-center gap-6 px-8 py-2 justify-between`}>
            <h3 onClick={() => openModal()} className="font-semibold text-sm hover:text-blue-500 cursor-pointer">
              Add + 1:1
            </h3>
          </div>

          {companyUserInfo?.managers?.id && (
            <>
              <div className="px-4 mt-5 ">
                <hr className="border-themeGray3" />
              </div>
              <div className={`flex items-center px-8 pt-2 pb-2 justify-start`}>
                <h3 className="font-semibold text-sm">Manager Goals</h3>
              </div>

              <div
                className={`flex items-center px-8 py-2 justify-start cursor-pointer ${
                  disableSidebar ? 'cursor-not-allowed pointer-events-none text-gray-500' : 'cursor-pointer'
                } ${getActiveClass(ownerNameList, companyUserInfo?.managers?.id)}`}
                onClick={() => {
                  navigate(`/goals/explore/${companyId}`)
                  onChipClose({ title: 'all' })
                  setFilter({
                    id: 'a',
                    name: 'Any',
                  })
                  setSearchInput('')
                  setTypeAny(true)
                  setStartDate(INITIAL_DATE_RANGE.startDate)
                  setDueDate(INITIAL_DATE_RANGE.dueDate)
                  ownerNameList.forEach((item) => (item.isActive = false))
                  let tempObj = ownerNameList.find((item) => item.id === companyUserInfo?.managers?.id)
                  setOwnerEmp(tempObj)
                  tempObj = {
                    ...tempObj,
                    isActive: true,
                  }
                  setHeaderLabel(tempObj)
                  onCheckboxChange(tempObj, ownersItems[0], true)
                }}
              >
                <Avatar
                  small
                  withName
                  name={truncate(companyUserInfo?.managers?.fullName)}
                  image={companyUserInfo?.managers?.profileImageThumbnailUrl}
                  nameClass={`font-semibold text-sm ${
                    disableSidebar ? 'cursor-not-allowed pointer-events-none text-gray-500' : 'cursor-pointer'
                  }`}
                />
              </div>
            </>
          )}

          <div className="px-4 mt-5 ">
            <hr className="border-themeGray3" />
          </div>

          <div
            onClick={() => navigate(`/company/${companyId}/departments`)}
            className={`cursor-pointer flex items-center px-8 py-2  justify-start`}
          >
            <h3 className="font-semibold text-sm">Department Goals</h3>
          </div>

          {companyUserInfo?.myProfile?.department[0] ? (
            companyUserInfo?.myProfile?.department?.map((department) => (
              <div
                className={`flex items-center px-8 py-2 justify-start cursor-pointer ${getActiveClass(
                  typeDepartmentList,
                  department.id
                )}`}
                onClick={() => {
                  setOwnerEmp('Anyone')
                  navigate(`/goals/explore/${companyId}`)
                  onChipClose({ title: 'all' })
                  setOwnerNameList((prevList) => {
                    return prevList.map((item) => {
                      if (item.id === 'anyone') {
                        return { ...item, isActive: true }
                      } else {
                        return { ...item, isActive: false }
                      }
                    })
                  })
                  setFilter({
                    id: 'a',
                    name: 'Any',
                  })
                  setSearchInput('')
                  setStartDate(INITIAL_DATE_RANGE.startDate)
                  setDueDate(INITIAL_DATE_RANGE.dueDate)
                  let tempDepObj = typeDepartmentList.find((item) => item.id === department.id)
                  tempDepObj = {
                    ...tempDepObj,
                    isActive: true,
                  }
                  setHeaderLabel(tempDepObj)
                  onCheckboxChange(tempDepObj, typeItems[2], true, true)
                }}
              >
                <h3 className={`font-semibold text-sm capitalize`}>
                  <Avatar small withName name={truncate(department?.name)} nameClass="font-semibold text-sm" />
                </h3>
              </div>
            ))
          ) : (
            <div className="my-2 font-semibold px-8">
              <h3 className="text-sm pl-3 border-l-2 border-pink-400">No Goals</h3>
            </div>
          )}
          <div className="px-4 mt-5 ">
            <hr className="border-themeGray3" />
          </div>

          <div
            onClick={() => navigate(`/company/${companyId}/teams`)}
            className={`flex items-center px-8 pt-2 pb-2 justify-start cursor-pointer`}
          >
            <h3 className={`font-semibold text-sm`}>Team Goals</h3>
          </div>
          {companyUserInfo?.team && companyUserInfo?.myTeam[0] ? (
            companyUserInfo?.myTeam.map((team) => (
              <div
                className={`flex items-center px-8 py-2 justify-start cursor-pointer ${getActiveClass(
                  typeTeamList,
                  team.team_id
                )}`}
                onClick={() => {
                  setOwnerEmp('Anyone')
                  navigate(`/goals/explore/${companyId}`)
                  onChipClose({ title: 'all' })
                  setOwnerNameList((prevList) => {
                    return prevList.map((item) => {
                      if (item.id === 'anyone') {
                        return { ...item, isActive: true }
                      } else {
                        return { ...item, isActive: false }
                      }
                    })
                  })
                  setFilter({
                    id: 'a',
                    name: 'Any',
                  })
                  setSearchInput('')
                  setStartDate(INITIAL_DATE_RANGE.startDate)
                  setDueDate(INITIAL_DATE_RANGE.dueDate)
                  let tempDepObj = typeTeamList.find((item) => item.id === team.team_id)
                  tempDepObj = {
                    ...tempDepObj,
                    isActive: true,
                  }
                  setHeaderLabel(tempDepObj)
                  onCheckboxChange(tempDepObj, typeItems[3], true, true)
                }}
              >
                <h3 className={`font-semibold text-sm capitalize`}>
                  <Avatar small withName name={truncate(team?.team_name)} nameClass="font-semibold text-sm" />
                </h3>
              </div>
            ))
          ) : (
            <div className="my-2 font-semibold px-8">
              <h3 className="text-sm pl-3 border-l-2 border-pink-400">No Goals</h3>
            </div>
          )}
        </>
      )}

      <div className="px-4 mt-5 ">
        <hr className="border-themeGray3" />
      </div>
      <div
        className={`flex items-center px-8 pt-2 pb-2 justify-start cursor-pointer ${typeCompany && selectedClass}`}
        onClick={() => {
          setOwnerEmp('Anyone')
          setFilter({
            id: 'a',
            name: 'Any',
          })
          setSearchInput('')
          setOwnerNameList((prevList) => {
            return prevList.map((item) => {
              if (item.id === 'anyone') {
                return { ...item, isActive: true }
              } else {
                return { ...item, isActive: false }
              }
            })
          })
          setHeaderLabel({ name: selectedCompany?.companyName, title: `${numberOfEmployees} employees` })
          typeTeamList.forEach((team) => (team.isActive = false))
          setTypeTeams([])
          setTypeTeam(false)
          typeDepartmentList.forEach((dept) => (dept.isActive = false))
          setTypeDepartments([])
          setTypeDepartment(false)
          setTypeIndividual(false)
          setTypeAny(false)
          setStartDate(INITIAL_DATE_RANGE.startDate)
          setDueDate(INITIAL_DATE_RANGE.dueDate)
          navigate(`/goals/explore/${companyId}`)
          setTypeCompany((prev) => !prev)
        }}
      >
        <h3 className="font-semibold text-sm mb-4">Company Goals</h3>
      </div>
    </div>
  )

  //GOAL DETAIL -> NUMBER RANGE (START-TARGET)
  const getRange = (selectedGoal, smHeight) => {
    const width = (selectedGoal?.progressValue * 100) / selectedGoal?.progressTargetValue
    return (
      <>
        {selectedGoal?.status === 'PROCESSING' ? (
          <div
            className={`absolute ${smHeight ? 'h-3' : 'h-4'} w-full bg-darkYellow rounded-full`}
            style={{ width: `${width > 100 ? '100' : width}%` }}
          ></div>
        ) : selectedGoal?.status === 'OFF_TRACK' ? (
          <div
            className={`absolute ${smHeight ? 'h-3' : 'h-4'} w-full bg-themeRed rounded-full`}
            style={{ width: `${width > 100 ? '100' : width}%` }}
          ></div>
        ) : selectedGoal?.status === 'ON_TRACK' ? (
          <div
            className={`absolute ${smHeight ? 'h-3' : 'h-4'} w-full bg-themeGreen3 rounded-full`}
            style={{ width: `${width > 100 ? '100' : width}%` }}
          ></div>
        ) : selectedGoal?.rollupChildCounts ? (
          <div
            className={`absolute ${smHeight ? 'h-3' : 'h-4'} w-full bg-gray-500 rounded-full`}
            style={{ width: `${width > 100 ? '100' : width}%` }}
          ></div>
        ) : (
          <div
            className={`absolute ${smHeight ? 'h-3' : 'h-4'} w-full bg-white rounded-full`}
            style={{ width: `${width > 100 ? '100' : width}%` }}
          ></div>
        )}
      </>
    )
  }

  const getProgress = (selectedGoal) => {
    if (selectedGoal?.progressType === 'NUMBER') {
      if (selectedGoal?.progressValue !== null) {
        return `${selectedGoal?.progressValue} / ${selectedGoal?.progressTargetValue} `
      } else {
        return `${selectedGoal?.progressStartValue} / ${selectedGoal?.progressTargetValue} `
      }
    } else if (selectedGoal?.progressType === 'PERCENT') {
      if (selectedGoal?.progressValue !== null) {
        return `${selectedGoal?.progressValue} %`
      } else {
        return `${selectedGoal?.progressStartValue} %`
      }
    } else if (selectedGoal?.progressType === 'BINARY') {
      if (selectedGoal?.isCompleted) {
        return 'Completed'
      } else {
        return 'In Progress'
      }
    }
  }

  //GOAL DETAIL -> HEADER BACKGROUND COLOR
  const getHeaderBackground = (selectedGoal) => {
    let bgColor = 'bg-gray-200 text-themeGray'

    if (selectedGoal?.hasEnded) {
      if (selectedGoal?.isCompleted) {
        bgColor = 'bg-themeBlue2 text-dark'
      } else {
        bgColor = 'bg-themeRed3 text-themeRed'
      }
    } else {
      if (selectedGoal?.status === 'PROCESSING') {
        bgColor = 'bg-lightYellow text-darkYellow2'
      } else if (selectedGoal?.status === 'OFF_TRACK') {
        bgColor = 'bg-red-100 text-themeRed'
      } else if (selectedGoal?.status === 'ON_TRACK') {
        bgColor = 'bg-green-100 text-themeGreen'
      }
    }
    return bgColor
  }

  //GOAL DETAIL -> HEADER_CORNER_LABEL
  const getLabel = (selectedGoal) => (
    <>
      {selectedGoal?.hasEnded &&
        (selectedGoal?.isCompleted ? (
          <div className="bg-white pt-1 rounded-md p-1 px-2 flex items-center space-x-2">
            <p className="text-dark flex items-center gap-x-1 text-sm font-semibold uppercase">
              <AiOutlineCheck className="text-base" />
              Complete
            </p>
          </div>
        ) : (
          <div className="bg-white pt-1 rounded-md p-1 px-2 flex items-center space-x-2">
            <p className="text-themeRed flex items-center gap-x-1 text-sm font-semibold uppercase">- - Incomplete</p>
          </div>
        ))}

      {!selectedGoal?.hasEnded &&
        (selectedGoal?.status === 'PROCESSING' ? (
          <div className={`bg-white pt-1 rounded-md p-1 px-2 flex items-center space-x-2`}>
            <ProcessingIcon className="text-xs" />
            <p className={`text-sm text-darkYellow font-semibold uppercase`}>Processing</p>
          </div>
        ) : selectedGoal?.status === 'OFF_TRACK' ? (
          <div className={`bg-white pt-1 rounded-md p-1 px-2 flex items-center space-x-2`}>
            <OffTrackIcon className="text-xs" />
            <p className={`text-sm text-themeRed font-semibold uppercase`}>Off track</p>
          </div>
        ) : selectedGoal?.status === 'ON_TRACK' ? (
          <div className={`bg-white pt-1 rounded-md p-1 px-2 flex items-center space-x-2`}>
            <OnTrackIcon className="text-xs" />
            <p className={`text-sm text-themeGreen3 font-semibold uppercase`}>On track</p>
          </div>
        ) : selectedGoal?.status === 'COMPLETED' ? (
          <div className="bg-white pt-1 rounded-md p-1 px-2 flex items-center space-x-2">
            <p className="text-dark flex items-center gap-x-1 text-sm font-semibold uppercase">
              <AiOutlineCheck className="text-base" />
              Complete
            </p>
          </div>
        ) : (
          <Label className="" label="- Not updated" labelColor="text-themeGray text-sm" bgColor="bg-white pt-1" />
        ))}
    </>
  )

  //GOAL DETAIL(POPUP) -> LABEL
  const getLabelGoalDetail = (selectedGoal) => (
    <>
      {selectedGoal?.hasEnded &&
        (selectedGoal?.isCompleted ? (
          <div className="bg-themeBlue2 py-2 flex justify-center rounded-lg">
            <p className="text-dark flex items-center gap-x-1 text-xs font-semibold">
              <AiOutlineCheck className="text-base" />
              Complete
            </p>
          </div>
        ) : (
          <div className="bg-themeRed2 py-2 flex justify-center rounded-lg">
            <p className="text-themeRed flex items-center gap-x-1 text-xs font-semibold">- - Incomplete</p>
          </div>
        ))}

      {!selectedGoal?.hasEnded &&
        (selectedGoal?.status === 'PROCESSING' ? (
          <div className={`bg-yellow-50 py-2 justify-center rounded-lg flex items-center space-x-2`}>
            <ProcessingIcon className="text-xs" />
            <p className={`text-xs text-darkYellow2 font-semibold uppercase`}>Processing</p>
          </div>
        ) : selectedGoal?.status === 'OFF_TRACK' ? (
          <div className={`bg-red-100 py-2 justify-center rounded-lg flex items-center space-x-2`}>
            <OffTrackIcon className="text-xs" />
            <p className={`text-xs text-themeRed font-semibold uppercase`}>Off track</p>
          </div>
        ) : selectedGoal?.status === 'ON_TRACK' ? (
          <div className={`bg-green-50 py-2 justify-center rounded-lg flex items-center space-x-2`}>
            <OnTrackIcon className="text-xs" />
            <p className={`text-xs text-themeGreen3 font-semibold uppercase`}>On track</p>
          </div>
        ) : (
          <div className="bg-gray-100 py-2 flex justify-center rounded-lg">
            <p className="text-themeGray flex items-center text-xs uppercase font-medium">
              <BiMinus />
              Not Updated
            </p>
          </div>
        ))}
    </>
  )

  const getLabelGoalList = (selectedGoal, onClick) => (
    <div onClick={onClick}>
      {selectedGoal?.hasEnded &&
        (selectedGoal?.isCompleted ? (
          <Tooltip text="Complete" position="top">
            <div className="bg-themeBlue2 w-10 h-8 py-1 flex justify-center rounded-lg">
              <p className="text-dark flex items-center gap-x-1 text-xs font-semibold">
                <AiOutlineCheck className="text-base" />
              </p>
            </div>
          </Tooltip>
        ) : (
          <Tooltip text="Incomplete" position="top">
            <div className="bg-themeRed2 py-1 w-10 h-8 flex justify-center rounded-lg">
              <p className="text-themeRed flex items-center gap-x-1 text-xs font-semibold">--</p>
            </div>
          </Tooltip>
        ))}

      {!selectedGoal?.isPublished && (
        <Tooltip text="Draft" position="top">
          <div className="bg-gray-100 w-10 h-8 py-1 justify-center rounded-lg flex items-center space-x-2">
            <BsCheck2Square className="h-3 w-3" />
          </div>
        </Tooltip>
      )}

      {!selectedGoal?.hasEnded &&
        selectedGoal?.isPublished &&
        (selectedGoal?.status === 'PROCESSING' ? (
          <Tooltip text="Processing" position="top">
            <div className={`bg-yellow-50 w-10 h-8 py-1 justify-center rounded-lg flex items-center space-x-2`}>
              <ProcessingIcon className="h-3 w-3" />
            </div>
          </Tooltip>
        ) : selectedGoal?.status === 'OFF_TRACK' ? (
          <Tooltip text="Off track" position="top">
            <div className={`bg-red-50 w-10 h-8 py-1 justify-center rounded-lg flex items-center space-x-2`}>
              <OffTrackIcon className="h-3 w-3" />
            </div>
          </Tooltip>
        ) : selectedGoal?.status === 'ON_TRACK' ? (
          <Tooltip text="On track" position="top">
            <div className={`bg-green-50 w-10 h-8 py-1 justify-center rounded-lg flex items-center space-x-2`}>
              <OnTrackIcon className="h-3 w-3" />
            </div>
          </Tooltip>
        ) : selectedGoal?.status === 'COMPLETED' ? (
          <Tooltip text="Complete" position="top">
            <div className="bg-themeBlue2 w-10 h-8 py-1 flex justify-center rounded-lg">
              <p className="text-dark flex items-center gap-x-1 text-xs font-semibold">
                <AiOutlineCheck className="text-base" />
              </p>
            </div>
          </Tooltip>
        ) : (
          <Tooltip text="Not Updated" position="top">
            <div className="bg-gray-100 py-1 w-10 h-8 flex justify-center rounded-lg">
              <p className="text-themeGray flex items-center text-xs uppercase font-medium">
                <BiMinus />
              </p>
            </div>
          </Tooltip>
        ))}
    </div>
  )

  const getGoalListTitle = (user, length) => (
    <div className="flex " key={user?.id}>
      <p className="truncate w-128 flex items-center gap-x-2">{lTruncate(user.name, { length })}</p>
    </div>
  )

  const getChipItems = (props) => {
    const {
      ownersManagers,
      ownersDepartmentIds,
      ownersTeamIds,
      ownersDirectReport,
      typeIndividual,
      typeCompany,
      visibilityPublic,
      visibilityPrivate,
      priorityList,
      typeDepartments,
      typeTeams,
      statusList,
      tagFilterList,
      typeDepartment,
      typeTeam,
      ownerNameList,
      supporterNameList,
      typeAny,
    } = props

    let showOwnersList = ''
    let tempList = []
    let ownersIndividualCount = 0
    ownerNameList?.forEach((item) => {
      if (item?.isActive) {
        tempList.push(item.name)
        ownersIndividualCount++
      }
    })

    let showSupportersList = ''
    let tempSupporterList = []
    let supportersIndividualCount = 0
    supporterNameList?.forEach((item) => {
      if (item?.isActive) {
        tempSupporterList.push(item.name)
        supportersIndividualCount++
      }
    })

    showSupportersList = tempSupporterList.join(', ')

    showOwnersList = tempList.join(', ')
    const showManagersList = ownersManagers?.map((item) => capitalize(item.name)).join(', ')
    const showDepartmentList = ownersDepartmentIds?.map((item) => capitalize(item.name)).join(', ')
    const showTeamList = ownersTeamIds?.map((item) => capitalize(item.name)).join(', ')

    if (showManagersList !== '') {
      showOwnersList = `Direct reports of (${showManagersList})${showOwnersList !== '' ? ', ' : ''}` + showOwnersList
    }

    if (showDepartmentList !== '') {
      showOwnersList =
        `Member of (${showDepartmentList})${showOwnersList !== '' || showManagersList !== '' ? ', ' : ''} ` +
        showOwnersList
    }

    if (showTeamList !== '') {
      showOwnersList =
        `Member of (${showTeamList})${showOwnersList !== '' || showManagersList !== '' ? ', ' : ''} ` + showOwnersList
    }

    if (ownersDirectReport) {
      showOwnersList =
        `Your direct reports${
          showOwnersList !== '' || showManagersList !== '' || showDepartmentList !== '' ? ', ' : ''
        }` + showOwnersList
    }

    let showTypeList = ''

    if (typeIndividual) {
      showTypeList = 'Individual'
    }

    if (typeAny) {
      showTypeList = 'Any'
    }

    if (typeCompany) {
      showTypeList = `Company`
    }

    let showVisibilityList = ''

    if (visibilityPublic) {
      showVisibilityList = 'Public' + showVisibilityList
    }

    if (visibilityPrivate) {
      showVisibilityList = `Private ${visibilityPublic ? ' OR ' : ''}` + showVisibilityList
    }

    let showPriority = []
    let countPriority = 0

    priorityList?.map((i, index) => {
      if (i?.isActive) {
        countPriority++
        showPriority.push(index + 1)
      }
    })

    let showTags = []
    let countTags = 0

    tagFilterList?.map((i, index) => {
      if (i?.isActive) {
        countTags++
        showTags.push(i?.name)
      }
    })

    let showStatus = []
    let countStatus = 0
    let tempStatus = ['On track', 'Processing', 'Off track', 'No update']

    statusList?.map((i, index) => {
      if (i?.isActive) {
        countStatus++
        if (i?.name === 'Any') {
          countStatus--
          showStatus.push('Any')
          tempStatus.forEach((item) => {
            showStatus = showStatus.filter((val) => val !== item)
          })
        } else {
          showStatus.push(i?.name)
        }
      }
    })

    let chipList = []

    if (showOwnersList !== '') {
      chipList.push({
        title: 'owner',
        text: showOwnersList,
      })
    }

    if (showSupportersList !== '') {
      chipList.push({
        title: 'supporters',
        text: showSupportersList,
      })
    }

    if (showTypeList !== '') {
      chipList.push({
        title: 'type',
        text: showTypeList,
      })
    }

    if (typeDepartments?.length > 0 && typeDepartment) {
      typeDepartments.map((item) => {
        chipList.push({
          id: item.id,
          title: 'type',
          text: `Dept - ${item.name}`,
        })
      })
    }

    if (typeTeams?.length > 0 && typeTeam) {
      typeTeams.map((item) => {
        chipList.push({
          id: item.id,
          title: 'type',
          text: `Team - ${item.name}`,
        })
      })
    }

    if (showVisibilityList !== '') {
      chipList.push({
        title: 'visibility',
        text: showVisibilityList,
      })
    }

    if (showPriority.length !== 0) {
      chipList.push({
        title: 'priority',
        text: showPriority.join(', '),
      })
    }

    if (showTags.length !== 0) {
      chipList.push({
        title: 'tag',
        text: showTags.join(', '),
      })
    }

    if (showStatus.length !== 0) {
      chipList.push({
        title: 'status',
        text: showStatus.join(', '),
      })
    }
    return {
      chipList: chipList,
      countPriority: countPriority,
      countTags: countTags,
      countStatus: countStatus,
      showStatus: showStatus,
      ownersIndividualCount,
      supportersIndividualCount,
    }
  }

  const responsiveClass = 'sm:max-w-172 lg:max-w-200 xl:max-w-256 ml-12 max-w-192'

  const exploreLoader = () => (
    <>
      <div className="bg-themeBlue w-full h-24 fixed top-0" style={{ zIndex: 1 }}>
        <header className="flex items-center">
          <div
            style={{ boxShadow: '0 2px 6px 4px rgba(212, 212, 212, 0.44)', zIndex: 4000 }}
            className={`flex ${responsiveClass} h-20 px-4 w-full transform rounded-lg translate-y-14 justify-between items-center bg-white`}
          >
            <div className="h-full flex items-center w-full justify-between">
              <div className="flex items-center">
                <div className="h-12 w-12 bg-gray-200 animate-pulse duration-700 rounded-full"></div>
                <div className="h-10 w-28 mx-3 bg-gray-200 animate-pulse duration-700 rounded"></div>
              </div>
              <div className="h-8 w-96 bg-gray-200 animate-pulse duration-700 rounded"></div>
            </div>
          </div>
        </header>
      </div>
      <div style={{ marginTop: '130px' }} className={`${responsiveClass} py-14 space-y-6`}>
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-bold">Explore</h1>
          <div className="h-10 w-20 bg-gray-200 animate-pulse duration-700 rounded"></div>
        </div>
        <div className="flex gap-x-2">
          <div className="h-10 w-3/4 bg-gray-200 animate-pulse duration-700 rounded"></div>
          <div className="h-10 w-1/4 bg-gray-200 animate-pulse duration-700 rounded"></div>
        </div>
        <div className="flex gap-x-2">
          <div className="h-10 w-1/12 bg-gray-200 animate-pulse duration-700 rounded"></div>
          <div className="h-10 w-11/12 bg-gray-200 animate-pulse duration-700 rounded"></div>
        </div>
        <div className="h-10 w-2/12 bg-gray-200 animate-pulse duration-700 rounded"></div>
        <hr />
        <div className="space-y-3">
          <div className="h-10 bg-gray-200 animate-pulse duration-700 rounded"></div>
          <div className="h-12 bg-gray-200 animate-pulse duration-700 rounded"></div>
          <div className="h-14 bg-gray-200 animate-pulse duration-700 rounded"></div>
          <div className="h-16 bg-gray-200 animate-pulse duration-700 rounded"></div>
          <div className="h-20 bg-gray-200 animate-pulse duration-700 rounded"></div>
        </div>
      </div>
    </>
  )

  const getCornerLabel = (selectedGoal) => (
    <>
      {selectedGoal?.hasEnded &&
        (selectedGoal?.isCompleted ? (
          <div
            style={{ borderTopRightRadius: '999px' }}
            className="h-10 w-14 rounded-tr rounded-bl-full flex justify-center items-center bg-themeBlue2 absolute top-0 right-0"
          >
            <Tooltip text="Complete" position="left">
              <AiOutlineCheck className="-mt-2 -mr-1" />
            </Tooltip>
          </div>
        ) : (
          <div
            style={{ borderTopRightRadius: '999px' }}
            className="h-10 w-14 rounded-tr rounded-bl-full flex justify-center items-center bg-themeRed2 absolute top-0 right-0"
          >
            <Tooltip text="Incomplete" position="left">
              <p className="text-themeRed flex items-center -mt-2 -mr-1 text-sm font-semibold uppercase">- -</p>
            </Tooltip>
          </div>
        ))}

      {!selectedGoal?.hasEnded &&
        (selectedGoal?.status === 'PROCESSING' ? (
          <div
            style={{ borderTopRightRadius: '999px' }}
            className={`h-10 w-14 rounded-tr rounded-bl-full flex justify-center items-center  bg-yellow-50 absolute top-0 right-0`}
          >
            <Tooltip text="Processing" position="left">
              <ProcessingIcon className="text-xs -mt-2 -mr-1" />
            </Tooltip>
          </div>
        ) : selectedGoal?.status === 'OFF_TRACK' ? (
          <div
            style={{ borderTopRightRadius: '999px' }}
            className={`h-10 w-14 rounded-tr rounded-bl-full flex justify-center items-center  bg-red-50 absolute top-0 right-0`}
          >
            <Tooltip text="Off track" position="left">
              <OffTrackIcon className="text-xs -mt-2 -mr-1" />
            </Tooltip>
          </div>
        ) : selectedGoal?.status === 'ON_TRACK' ? (
          <div
            style={{ borderTopRightRadius: '999px' }}
            className={`h-10 w-14 rounded-tr rounded-bl-full flex justify-center items-center  bg-green-50 absolute top-0 right-0`}
          >
            <Tooltip text="On track" position="left">
              <OnTrackIcon className="text-xs -mt-2 -mr-1" />
            </Tooltip>
          </div>
        ) : (
          <div
            style={{ borderTopRightRadius: '999px' }}
            className="h-10 w-14 rounded-tr rounded-bl-full flex justify-center items-center  bg-themeGray3 absolute top-0 right-0"
          >
            <Tooltip text="Not updated" position="left">
              <AiOutlineMinus className="text-xs -mt-2 -mr-1" />
            </Tooltip>
          </div>
        ))}
    </>
  )

  const getSmallRange = (selectedGoal) => {
    const width = (selectedGoal?.progressValue * 100) / selectedGoal?.progressTargetValue
    return (
      <>
        {selectedGoal?.status === 'PROCESSING' ? (
          <div
            className={`absolute h-1 bg-darkYellow rounded-full`}
            style={{ width: `${width > 100 ? '100' : width}%` }}
          ></div>
        ) : selectedGoal?.status === 'OFF_TRACK' ? (
          <div
            className={`absolute  h-1 bg-themeRed rounded-full`}
            style={{ width: `${width > 100 ? '100' : width}%` }}
          ></div>
        ) : selectedGoal?.status === 'ON_TRACK' ? (
          <div
            className={`absolute  h-1 bg-themeGreen3 rounded-full`}
            style={{ width: `${width > 100 ? '100' : width}%` }}
          ></div>
        ) : (
          <div
            className={`absolute  h-1 bg-black rounded-full`}
            style={{ width: `${width > 100 ? '100' : width}%` }}
          ></div>
        )}
      </>
    )
  }

  const filterOwnersItems = (props) => {
    const {
      ownersIndividualCount,
      nameListNotFound,
      ownersManagers,
      ownersDepartmentIds,
      ownersDirectReport,
      ownerNameList,
      nameSearchInput,
      setNameSearchInput,
    } = props

    const items = [
      {
        id: 'a1',
        name: 'Owners',
        title: true,
        description: '',
        search: true,
        searchInput: nameSearchInput,
        checkBox: true,
        onChangeSearch: setNameSearchInput,
        count:
          ownersIndividualCount + ownersManagers.length + ownersDepartmentIds.length + (ownersDirectReport ? 1 : 0),
        listNotFound: nameListNotFound,
        onClick: () => {},
        children: ownerNameList,
      },
    ]
    return items
  }

  const filterSupportersItems = (props) => {
    const {
      supportersIndividualCount,
      nameListNotFound,
      supporterNameList,
      supporterSearchInput,
      setSupporterSearchInput,
    } = props

    const items = [
      {
        id: 's',
        name: 'Supporter',
        title: true,
        description: '',
        search: true,
        searchInput: supporterSearchInput,
        checkBox: true,
        count: supportersIndividualCount,
        listNotFound: nameListNotFound,
        onChangeSearch: setSupporterSearchInput,
        onClick: () => {},
        children: supporterNameList,
      },
    ]
    return items
  }

  const filterTypeItems = (props) => {
    const {
      typeDepartments,
      typeIndividual,
      typeCompany,
      setTypeIndividual,
      typeDepartmentList,
      setTypeCompany,
      typeTeams,
      typeTeamList,
      typeAny,
      setTypeAny,
      setTypeTeam,
      setTypeTeams,
      setTypeDepartment,
      setTypeDepartments,

      setHeaderLabel,
      selectedCompany,
      numberOfEmployees,
      departmentSearch,
      setDepartmentSearch,
      teamSearch,
      setTeamSearch,
    } = props

    const items = [
      {
        id: 'b0',
        name: 'Any',
        onClick: () => {
          setDepartmentSearch('')
          setTeamSearch('')
          setHeaderLabel({ name: 'Explore goals', title: '' })
          typeTeamList.forEach((team) => (team.isActive = false))
          setTypeTeams([])
          setTypeTeam(false)
          typeDepartmentList.forEach((dept) => (dept.isActive = false))
          setTypeDepartments([])
          setTypeDepartment(false)
          setTypeIndividual(false)
          setTypeCompany(false)
          setTypeAny(typeAny ? false : true)
        },
        active: typeAny,
      },
      {
        id: 'b1',
        name: 'Individual',
        onClick: () => {
          setDepartmentSearch('')
          setTeamSearch('')
          typeTeamList.forEach((team) => (team.isActive = false))
          setTypeTeams([])
          setTypeTeam(false)
          typeDepartmentList.forEach((dept) => (dept.isActive = false))
          setTypeDepartments([])
          setTypeDepartment(false)
          setTypeIndividual(typeIndividual ? false : true)
          setTypeAny(false)
          setTypeCompany(false)
        },
        active: typeIndividual,
      },
      {
        id: 'b2',
        name: 'Department',
        checkBox: true,
        count: typeDepartments.length,
        items: typeDepartmentList,
        search: true,
        searchInput: departmentSearch,
        onChangeSearch: setDepartmentSearch,
        onclick: () => {
          setDepartmentSearch('')
          setTeamSearch('')
        },
      },
      {
        id: 'b4',
        name: 'Team',
        checkBox: true,
        count: typeTeams?.length,
        items: typeTeamList,
        searchInput: teamSearch,
        onChangeSearch: setTeamSearch,
        search: true,
        onclick: () => {
          setDepartmentSearch('')
          setTeamSearch('')
        },
      },
      {
        id: 'b3',
        name: 'Company',
        onClick: () => {
          setHeaderLabel({ name: selectedCompany?.companyName, title: `${numberOfEmployees} employees` })
          typeTeamList.forEach((team) => (team.isActive = false))
          setTypeTeams([])
          setTypeTeam(false)
          typeDepartmentList.forEach((dept) => (dept.isActive = false))
          setTypeDepartments([])
          setTypeDepartment(false)
          setTypeCompany(typeCompany ? false : true)
          setTypeAny(false)
          setTypeIndividual(false)
        },
        active: typeCompany,
      },
    ]
    return items
  }

  const filterStatusItems = (props) => {
    const { countStatus, statusList } = props

    const items = [
      {
        id: 'd',
        name: 'Status',
        checkBox: true,
        count: countStatus,
        children: statusList,
      },
    ]
    return items
  }

  const onFilterCheck = (props) => {
    const {
      item,
      childItem,
      ownerList,
      ownerNameList,
      setOwnerNameList,
      supporter,
      supporterNameList,
      setSupporterNameList,
      onNameChange,
      setOnNameChange,
      ownerManagerList,
      setOwnerManagerList,
      setOwnersManagers,
      ownersManagers,
      ownerDepartmentList,
      setOwnerDepartmentList,
      setOwnersDepartmentIds,
      ownersDepartmentIds,
      typeDepartmentList,
      setTypeDepartmentList,
      setTypeDepartment,
      setTypeDepartments,
      typeDepartments,
      setVisibilityPublic,
      setVisibilityPrivate,
      priority,
      priorityList,
      setPriorityList,
      tag,
      tagFilterList,
      setTagFilterList,
      setAllActive,
      status,
      statusList,
      setStatusList,
      ownerTeamList,
      setOwnerTeamList,
      setOwnersTeamIds,
      ownersTeamIds,
      setTypeTeamList,
      setTypeTeam,
      setTypeTeams,
      typeTeamList,
      typeTeams,
      setHeaderLabel,
      isReset,
      setTypeAny,
      setTypeIndividual,
      setTypeCompany,
    } = props

    if (item?.id === 'a1') {
      const updatedOwner = ownerList.map((i) => {
        if (childItem?.name === 'Anyone') {
          setHeaderLabel({ name: 'Explore goals', title: '' })
        } else {
          setHeaderLabel(childItem)
        }
        if (i?.id === childItem?.id) {
          return {
            ...i,
            isActive: true,
          }
        } else {
          return {
            ...i,
            isActive: false,
          }
        }
      })
      setOwnerNameList(updatedOwner)
      setOnNameChange(onNameChange ? false : true)
    }

    if (item?.id === 'a2') {
      const tempList = ownerManagerList.map((item) => {
        if (childItem?.name === 'Anyone') {
          setHeaderLabel({ name: 'Explore goals', title: '' })
        } else {
          setHeaderLabel(childItem)
        }
        if (item?.id === childItem?.id) {
          return (item = childItem)
        } else {
          return item
        }
      })
      setOwnerManagerList(tempList)
      setOwnersManagers([...ownersManagers, childItem])
    }

    if (item?.id === 'a3') {
      const tempList = ownerDepartmentList.map((item) => {
        if (childItem?.name === 'Anyone') {
          setHeaderLabel({ name: 'Explore goals', title: '' })
        } else {
          setHeaderLabel(childItem)
        }
        if (item?.id === childItem?.id) {
          return (item = childItem)
        } else {
          return item
        }
      })
      setOwnerDepartmentList(tempList)
      setOwnersDepartmentIds([...ownersDepartmentIds, childItem])
    }

    if (item?.id === 'a4') {
      const tempList = ownerTeamList.map((item) => {
        if (childItem?.name === 'Anyone') {
          setHeaderLabel({ name: 'Explore goals', title: '' })
        } else {
          setHeaderLabel(childItem)
        }
        if (item?.id === childItem?.id) {
          return (item = childItem)
        } else {
          return item
        }
      })
      setOwnerTeamList(tempList)
      setOwnersTeamIds([...ownersTeamIds, childItem])
    }

    if (item?.id === 'b2') {
      typeTeamList.forEach((team) => (team.isActive = false))
      setTypeTeams([])
      setTypeTeam(false)
      setTypeAny(false)
      setTypeIndividual(false)
      setTypeCompany(false)
      setOwnerNameList((prevList) => {
        return prevList.map((item) => {
          if (item.id === 'anyone') {
            return { ...item, isActive: true }
          } else {
            return { ...item, isActive: false }
          }
        })
      })
      setTypeDepartmentList((prevList) =>
        prevList.map((item) => {
          setHeaderLabel(childItem)
          if (item?.id === childItem?.id) {
            return (item = childItem)
          } else {
            return item
          }
        })
      )
      setTypeDepartment(true)
      isReset ? setTypeDepartments([childItem]) : setTypeDepartments([childItem])
    }

    if (item?.id === 'b4') {
      typeDepartmentList.forEach((dept) => (dept.isActive = false))
      setTypeDepartments([])
      setTypeDepartment(false)
      setTypeAny(false)
      setTypeIndividual(false)
      setTypeCompany(false)
      setOwnerNameList((prevList) => {
        return prevList.map((item) => {
          if (item.id === 'anyone') {
            return { ...item, isActive: true }
          } else {
            return { ...item, isActive: false }
          }
        })
      })
      setTypeTeamList((prevList) =>
        prevList.map((item) => {
          setHeaderLabel(childItem)
          if (item?.id === childItem?.id) {
            return (item = childItem)
          } else {
            return item
          }
        })
      )
      setTypeTeam(true)
      isReset ? setTypeTeams([childItem]) : setTypeTeams([childItem])
    }

    if (item?.id === 'f1') {
      setVisibilityPublic(true)
    }

    if (item?.id === 'f2') {
      setVisibilityPrivate(true)
    }

    if (!isEmpty(priority)) {
      const updatedPriority = priorityList.map((i) => {
        if (i?.id === priority?.id) {
          return {
            ...i,
            isActive: true,
          }
        } else {
          return i
        }
      })
      setPriorityList(updatedPriority)
    }

    if (item?.id === 's') {
      const updatedSupporter = ownerList.map((i) => {
        if (childItem?.name === 'Anyone') {
          setHeaderLabel({ name: 'Explore goals', title: '' })
        } else {
          setHeaderLabel(childItem)
        }
        if (i?.id === childItem?.id) {
          return {
            ...i,
            isActive: true,
          }
        } else {
          return {
            ...i,
            isActive: false,
          }
        }
      })
      setSupporterNameList(updatedSupporter)
    }

    if (item.id === 'e') {
      const updatedTag = tagFilterList.map((i) => {
        if (i?.id === tag?.id) {
          return {
            ...i,
            isActive: true,
          }
        } else {
          return i
        }
      })
      setTagFilterList(updatedTag)
    }

    if (!isEmpty(status)) {
      statusList.forEach((item) => (item.isActive = false))
      const updatedStatus = statusList.map((i) => {
        if (i.id === childItem.id) {
          return {
            ...i,
            isActive: true,
          }
        } else {
          return {
            ...i,
            isActive: false,
          }
        }
      })
      setStatusList(updatedStatus)
    }
  }

  const onFilterUncheck = (props) => {
    const {
      item,
      childItem,
      ownerList,
      ownerNameList,
      setOwnerNameList,
      supporter,
      supporterNameList,
      setSupporterNameList,
      onNameChange,
      setOnNameChange,
      ownerManagerList,
      setOwnerManagerList,
      setOwnersManagers,
      ownersManagers,
      ownerDepartmentList,
      setOwnerDepartmentList,
      setOwnersDepartmentIds,
      ownersDepartmentIds,
      typeDepartmentList,
      setTypeDepartmentList,
      setTypeDepartment,
      setTypeDepartments,
      typeDepartments,
      setVisibilityPublic,
      setVisibilityPrivate,
      priority,
      priorityList,
      setPriorityList,
      tag,
      tagFilterList,
      setTagFilterList,
      setAllActive,
      status,
      statusList,
      setStatusList,
      setOwnersTeamIds,
      setOwnerTeamList,
      ownerTeamList,
      ownersTeamIds,
      typeTeamList,
      setTypeTeamList,
      setTypeTeams,
      typeTeams,
      setTypeTeam,
      setHeaderLabel,
    } = props

    setHeaderLabel({ name: 'Explore goals' })

    if (item.id === 'a1') {
      const updatedOwner = ownerList.map((i) => {
        if (i.id === childItem.id) {
          return {
            ...i,
            isActive: false,
          }
        } else {
          return i
        }
      })
      setOwnerNameList(updatedOwner)
      setOnNameChange(onNameChange ? false : true)
    }

    if (!isEmpty(supporter)) {
      const updateNameList = ownerList.map((item) => {
        if (item.id === childItem.id) {
          return (item = childItem)
        } else {
          return item
        }
      })
      setSupporterNameList(updateNameList)
      setOnNameChange(onNameChange ? false : true)
    }

    if (item.id === 'a2') {
      const updateManagerList = ownerManagerList.map((item) => {
        if (item.id === childItem.id) {
          return (item = childItem)
        } else {
          return item
        }
      })
      setOwnerManagerList(updateManagerList)
      const tempList = ownersManagers.filter((item) => item.id !== childItem.id)
      setOwnersManagers(tempList)
    }

    if (item.id === 'a3') {
      const updateDepartmentList = ownerDepartmentList.map((item) => {
        if (item.id === childItem.id) {
          return (item = childItem)
        } else {
          return item
        }
      })
      setOwnerDepartmentList(updateDepartmentList)
      const tempList = ownersDepartmentIds.filter((item) => item.id !== childItem.id)
      setOwnersDepartmentIds(tempList)
    }

    if (item.id === 'a4') {
      const updateTeamList = ownerTeamList.map((item) => {
        if (item.id === childItem.id) {
          return (item = childItem)
        } else {
          return item
        }
      })
      setOwnerTeamList(updateTeamList)
      const tempList = ownersTeamIds.filter((item) => item.id !== childItem.id)
      setOwnersTeamIds(tempList)
    }

    if (item.id === 'b2') {
      const updateDepartmentList = typeDepartmentList.map((item) => {
        if (item.id === childItem.id) {
          return (item = childItem)
        } else {
          return item
        }
      })
      setTypeDepartmentList(updateDepartmentList)

      setTypeDepartment(true)
      const tempList = typeDepartments.filter((item) => item.id !== childItem.id)
      if (tempList.length === 0) {
        setTypeDepartment(false)
      }
      setTypeDepartments(tempList)
    }

    if (item.id === 'b4') {
      const updateTeamList = typeTeamList.map((item) => {
        if (item.id === childItem.id) {
          return (item = childItem)
        } else {
          return item
        }
      })
      setTypeTeamList(updateTeamList)

      setTypeTeam(true)
      const tempList = typeTeams.filter((item) => item.id !== childItem.id)
      if (tempList.length === 0) {
        setTypeTeam(false)
      }
      setTypeTeams(tempList)
    }

    if (item.id === 'f1') {
      setVisibilityPublic(false)
    }

    if (item.id === 'f2') {
      setVisibilityPrivate(false)
    }

    if (!isEmpty(priority)) {
      const updatedPriority = priorityList.map((i) => {
        if (i.id === priority.id) {
          return {
            ...i,
            isActive: false,
          }
        } else {
          return i
        }
      })
      setPriorityList(updatedPriority)
    }

    if (item?.id === 's') {
      const updatedSupporter = supporterNameList.map((i) => {
        if (i.id === childItem.id) {
          return {
            ...i,
            isActive: false,
          }
        } else {
          return i
        }
      })
      setSupporterNameList(updatedSupporter)
    }

    if (!isEmpty(tag)) {
      const updatedTag = tagFilterList.map((i) => {
        if (i.id === tag.id) {
          return {
            ...i,
            isActive: false,
          }
        } else {
          return i
        }
      })
      setTagFilterList(updatedTag)
    }

    if (!isEmpty(status)) {
      if (status?.id === 'd1') {
        setAllActive(false)
      } else if (status?.id !== 'd1') {
        const updatedStatus = statusList.map((i) => {
          if (i.id === status.id) {
            return {
              ...i,
              isActive: false,
            }
          } else {
            return i
          }
        })
        setStatusList(updatedStatus)
      }
    }
  }

  return {
    sidebarContent,
    getRange,
    getHeaderBackground,
    getLabel,
    getProgress,
    getLabelGoalDetail,
    getLabelGoalList,
    getGoalListTitle,
    getChipItems,
    exploreLoader,
    getCornerLabel,
    getSmallRange,
    filterOwnersItems,
    filterSupportersItems,
    filterTypeItems,
    filterStatusItems,
    onFilterCheck,
    onFilterUncheck,
    ownerEmp,
    setOwnerEmp,
    setDisableSidebar,
  }
}
