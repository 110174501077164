import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTable, useExpanded, useBlockLayout, useResizeColumns } from 'react-table'
import moment from 'moment'

const Styles = styled.div`
  padding-top: 1.5rem;

  .table {
    width: 100%;
    display: table;
    border-collapse: collapse;

    .th,
    .td {
      padding: 0.75rem;
      border-bottom: 1px solid rgba(229, 231, 235);
    }

    .th {
      font-size: 0.75rem;
      font-weight: 700;
    }

    .td {
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .tr:hover {
      background-color: #f1f5f9;
      cursor: pointer;
    }

    .resizer {
      display: none;
    }
  }
`

function Table({ columns: userColumns, data, fetchGoal, setUpdate, setViewModal }) {
  const [selectedRow, setSelectedRow] = useState(null)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: userColumns,
      data,
      getSubRows: (row) => row.children,
    },
    useBlockLayout,
    useResizeColumns,
    useExpanded,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns])
    }
  )

  useEffect(() => {
    const handleKeyDown = (event) => {
      let newSelectedRow = selectedRow

      if (event.key === 'ArrowDown') {
        // Select next row
        const nextRowIndex = rows.findIndex((row) => row.original.id === selectedRow) + 1
        if (nextRowIndex < rows.length) {
          newSelectedRow = rows[nextRowIndex].original.id
        }
      }

      if (event.key === 'ArrowUp') {
        // Select previous row
        const prevRowIndex = rows.findIndex((row) => row.original.id === selectedRow) - 1
        if (prevRowIndex >= 0) {
          newSelectedRow = rows[prevRowIndex].original.id
        }
      }

      if (event.key === 'Enter') {
        setSelectedRow(newSelectedRow)
        fetchGoal(newSelectedRow)
        setViewModal(true)
        setUpdate(false)
      }

      if (newSelectedRow !== selectedRow) {
        setSelectedRow(newSelectedRow)

        // After updating the selected row, scroll it into view
        const rowElement = document.getElementById(`row-${newSelectedRow}`)
        if (rowElement) {
          rowElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedRow, rows])

  return (
    <>
      <div>
        <div {...getTableProps()} className="table">
          <div>
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column) => (
                  <div {...column.getHeaderProps()} className="th">
                    {column.render('Header')}
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <div
                  id={`row-${row.original.id}`} // Unique ID for each row for scrolling
                  onClick={() => setSelectedRow(row.original.id)}
                  {...row.getRowProps()}
                  className={`${row.original.id === selectedRow ? 'bg-blue-500 text-black' : ''} cursor-pointer tr`}
                >
                  {row.cells.map((cell) => {
                    const isSelected = row.original.id === selectedRow
                    return (
                      <div {...cell.getCellProps()} className={`td ${isSelected ? 'text-black' : ''}`}>
                        {cell.render('Cell')}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

function GoalsTree({ data, status, owner, progress, subGoalsCount, setViewModal, fetchGoal, setUpdate }) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'TITLE',
        accessor: 'name',
        Cell: ({ cell: { value }, row }) => {
          return (
            <div
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 1.4}rem`,
                },
              })}
              className="cursor-pointer h-full flex items-center gap-2"
            >
              {/* {row.canExpand ? (
                <span>
                  <IoIosArrowUp
                    className={`transition-all ${row.isExpanded ? 'transform rotate-180' : 'transform rotate-90'}`}
                  />
                </span>
              ) : null} */}
              <span
                onClick={() => {
                  fetchGoal(row.original.id)
                  setViewModal(true)
                  setUpdate(false)
                }}
              >
                {value}
              </span>
            </div>
          )
        },
        width: Math.round(window.innerWidth * 0.35),
        minWidth: Math.round(window.innerWidth * 0.25),
        maxWidth: Math.round(window.innerWidth * 0.4),
      },
      {
        Header: () => <span className="w-full flex justify-center">PROGRESS</span>,
        accessor: 'progress',
        Cell: ({ row }) => {
          return (
            <div
              onClick={() => {
                fetchGoal(row.original.id)
              }}
              className="w-full flex justify-center self-center"
            >
              {progress(row.original)}
            </div>
          )
        },
        width: Math.round(window.innerWidth * 0.08),
        minWidth: Math.round(window.innerWidth * 0.08),
        maxWidth: Math.round(window.innerWidth * 0.08),
      },
      {
        Header: () => <span className="w-full flex justify-center">STATUS</span>,
        accessor: 'status',
        Cell: ({ row }) => {
          return (
            <div
              onClick={() => {
                fetchGoal(row.original.id)
              }}
              className="w-full flex justify-center"
            >
              {status(row.original)}
            </div>
          )
        },
        width: Math.round(window.innerWidth * 0.08),
        minWidth: Math.round(window.innerWidth * 0.08),
        maxWidth: Math.round(window.innerWidth * 0.08),
      },
      {
        Header: () => <span className="w-full flex justify-center">SUBGOALS</span>,
        accessor: 'children',
        Cell: ({ row }) => {
          return <>{subGoalsCount(row.original)}</>
        },
        width: Math.round(window.innerWidth * 0.08),
        minWidth: Math.round(window.innerWidth * 0.08),
        maxWidth: Math.round(window.innerWidth * 0.08),
      },
      {
        Header: () => <span className="w-full flex justify-center">OWNER</span>,
        accessor: 'owner',
        Cell: ({ row }) => {
          return <>{owner(row.original)}</>
        },
        width: Math.round(window.innerWidth * 0.08),
        minWidth: Math.round(window.innerWidth * 0.08),
        maxWidth: Math.round(window.innerWidth * 0.08),
      },
      {
        Header: () => <span className="w-full flex justify-center">DUE</span>,
        accessor: 'dueDate',
        Cell: ({ cell: { value } }) => {
          return (
            <div className="w-full h-full flex items-center justify-center">
              <span>{moment(value).format('MM/DD/YYYY')}</span>
            </div>
          )
        },
        width: Math.round(window.innerWidth * 0.08),
        minWidth: Math.round(window.innerWidth * 0.08),
        maxWidth: Math.round(window.innerWidth * 0.08),
      },
    ],
    []
  )

  return (
    <Styles>
      <Table columns={columns} data={data} fetchGoal={fetchGoal} setViewModal={setViewModal} setUpdate={setUpdate} />
    </Styles>
  )
}

export default GoalsTree
